/* chatbot-popup.css */
.chatbot-popup-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure the chatbot appears above other elements */
}

.chatbot-popup {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 500px; /* Adjust the width as needed */
  /* Adjust the maximum height as needed */
  overflow: auto;
}

/* Styling for chatbot header */
.chatbot-popup-header {
  background-color: #18202f;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Styling for chatbot messages */
.chatbot-popup-messages {
  padding: 10px;
}

/* Styling for chatbot input */
.chatbot-popup-input {
  padding: 10px;
  border-top: 1px solid #ddd;
}

/* Styling for chatbot input field */
.chatbot-popup-input input {
  width: calc(100% - 20px);
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.react-chatbot-kit-chat-container {
  width: 100% !important;
}
.react-chatbot-kit-chat-bot-message {
  width: 100% !important;
  background-color: #18202f !important;
}
.react-chatbot-kit-chat-input-container {
  display: none !important;
}
.react-chatbot-kit-chat-message-container {
  overflow: auto !important;
}
