input,
textarea {
  background-color: #ecf0f3 !important;
  border: 0px !important;

  border-radius: 8px !important;
  margin: 4px 0;
  padding-left: 25px;
  font-size: 13px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: #ecf0f3;
  transition: 0.25s ease;
  border-radius: 8px;
}

.divider.text {
  color: #18202f !important;
}
label {
  margin-bottom: 16px !important;
}
#form-input-control-project {
  border: 0px !important;
  background-color: rgb(242, 241, 239) !important;
}

#form-input-control-project:hover {
  transform: scale3d(1.01, 1.2, 12);
}

.modal {
  height: auto;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
}
.ringchart {
  color: white !important;
  padding: 1.25rem !important;
}

.ui.modal-backdrop {
  opacity: 0.1 !important;
}

.checkbox {
  width: 25%;
  padding: 5px;
}
.csv {
  background-color: #fdb827 !important;
  color: black !important;
  border: 1px solid white !important;
  font-size: 11px !important;
  opacity: 0.9;
  padding: 10px 15px 10px 15px !important;
}
.header {
  font-weight: 400 !important;
  padding-left: 20px !important;
  padding-right: 10px !important;
}
.ui.modal {
  background-color: #ffffff !important;
}

.ui.modal > .close {
  margin-right: 5%;
  top: 0rem !important;
  color: black !important;
  opacity: 1 !important;
  font-size: 2em !important;
}
.ui.fullscreen.scrolling.modal {
  left: 20px !important;
  right: 0px !important;
}
.ui.fullscreen.modal {
  width: 98% !important;
}

.table {
  max-width: 100%;
  width: 100%;
  padding-top: 2% !important;
  padding-bottom: 0.5% !important;
  padding-left: 0.5 !important;

  border-radius: 0.55rem !important;
}

.table__row {
  display: flex;
  align-items: center;
  background-color: #ecf0f3;
  padding: 15px 0;
  transition: 0.2s ease;
  font-size: 1rem !important;
  color: grey;
  margin-bottom: 0.5%;

  border-bottom: 1px solid #ecf0f3;
}

@media screen and (max-width: 580px) {
  .table__row {
    flex-direction: column;
    justify-content: center;
  }
}

.table__row:nth-child(odd):hover {
  background-color: #f5f5f7;
}
.table__heading {
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
  color: white;
  background-color: #18202f !important;
}
@media screen and (max-width: 580px) {
  .table__heading {
    display: none;
  }
}

.table__crypto-name {
  font-weight: bold;
  font-size: 14px;
  color: black;
}
.table__crypto-image {
  margin-right: 10px;
}
@media screen and (max-width: 580px) {
  .table__crypto-image {
    margin-right: 0;
  }
}
.button {
  border-radius: 4px;
  font-size: 12px;
  font-size: 1rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s ease;

  padding: 10px 10px;
}

.button--outline {
  border: 1px solid #556fe7;
  color: #fdb827;
}
.button--outline:hover {
  background-color: transparent;
  color: black;
}
.button--primary {
  color: #fdb827;
  background-color: transparent;
  border: 1px solid transparent;
  font-weight: 600;
}
.button--primary:hover {
  background-image: linear-gradient(to right, transparent, transparent);
  color: #fdb827;
  border: 1px solid black;
}
.buttons__ventar {
  border: 0.0625rem solid #18202f;
  background-color: transparent !important;
  color: #18202f;
  font-size: 10px;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

button:focus {
  outline: none;
}

.field,
.ui.input {
  width: 100% !important;
}

.tcfd .tab-content,
.tcfd .nav {
  margin-top: 3% !important;
}
.card_eg {
  background: #ffffff;
  border: 1px solid lightgray !important;
  border-radius: 0px !important;
  margin-top: 3%;
  /* background: conic-gradient(
    from 42.7deg at 50% 50%,
    #256eff 0deg,
    #256eff 360deg
  );*/

  height: 94% !important;
}

.card_title {
  background: #355ed814 !important;

  color: #18202f !important;
  font-family: "montserrat" !important;
  padding-bottom: 8% !important;
  width: 100% !important;
  padding: 4% !important;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.09em;
}

.img-pr {
  position: absolute;
  width: 30px;
  height: 30px;
  margin-right: 20%;
}

.card_btm_row {
  background: #f7f7f7;
  border-radius: 0px 0px 10px 10px;
  padding: 2%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.card_info1a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #9f9f9f;
  text-align: left !important;
}

.card_info1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #18202f;
  text-align: left !important;
}

.card_info2a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #9f9f9f;
  text-align: left !important;
}

.card_info2 {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 22px !important;
  color: #18202f !important;
  text-align: left !important;
}

.card_info3a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #9f9f9f;
  text-align: left !important;
}

.card_info3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #18202f;
  text-align: left !important;
}
.card_eg .ant-progress-line {
  width: 100% !important;
}

.card_eg_top {
  height: 50%;

  border-radius: 15px;
}

.project_card {
  background: #ffffff;
  /*border: 5px solid #dcdcdc;*/
  border-radius: 15px;
}

.card_eg .ui.progress .bar {
  background: #fdb827 !important;
}
.card_eg .ui.progress .bar {
  height: 0.5em !important;
}

#trtab1 {
  width: 26% !important;
  position: relative;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 15px;
  margin-left: 15%;
  border: 3px solid #18202f;
  padding: 2%;
  font-size: 2.3em !important;
  line-height: 99%;
  margin-top: 10% !important;
}

#trtab2 {
  width: 26% !important;
  position: relative;
  pointer-events: none;
  align-items: center;
  text-align: left !important;
  border-radius: 15px;
  color: #395285;
  border: 3px solid #395285;
  padding: 3.8%;
  margin-left: 10%;
  font-size: 2.3em !important;
  line-height: 99%;
  margin-top: 10% !important;
}

#href_transition {
  margin-top: -40%;
}

#new_transition {
  position: absolute;
  margin-top: -28%;
  font-size: 1em;
  margin-left: 16%;
  background-color: transparent;
  padding-top: 5%;
}

.download_csv {
  background: #fff !important;
  color: grey !important;
  font-size: 14px !important;
  width: 100px !important;
  border-radius: 5px !important;
}
.download_csv .divider.text {
  color: grey !important;
  background-color: #fff !important;
}
.download_csv .icon {
  display: none !important;
}

.circle_project .ant-progress-inner {
  height: 80px !important;
  width: 80px !important;
  font-size: 15px !important;
  margin-left: 25% !important;
}

.buttons__comprar {
  padding: 0px !important;
}
.project_csv p {
  margin-bottom: 0.3% !important;
}
.project_csv .row {
  margin-bottom: 3% !important;
}
.project_csv .dropdown {
  border-radius: 0% !important;
}
