.progress1 {
  font-size: 8px !important;
}
.ant-progress-circle-path {
  stroke: red !important;
}

td {
  font-size: 15px;
}
.ui.progress .bar,
.ant-progress-bg {
  background: #18202f !important;
  min-width: 0em !important;
}

.Legend {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.filter {
  background-color: #ffffff !important;
}

circle {
  margin-left: 30% !important;
  margin-top: 5%;
}
.circle svg {
  width: 100px !important;
  height: 100px !important;
}

.box {
  margin-top: 2%;

  margin-right: 1%;
}
.styles_square__2WCBo {
  border: 1px solid white !important;
}

.legend li {
  padding-left: 45px !important;
}
.ui.selection.dropdown {
  background: #fcfcfc !important;
  border: 1px solid rgba(120, 120, 120, 0.2) !important;

  min-width: 7em !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: #d0e0ff !important;

  font-weight: 100 !important;
  padding: -2% !important;
}

.ui.selection.active.dropdown .menu {
  background: #f5f5f5 !important;

  border: none !important;
}

.localactive {
  background-color: #005edc !important;
  color: white !important;
}

.globalactive {
  background-color: #005edc !important;
  color: white !important;
}
.inactive {
  background-color: white !important;
  color: #005edc !important;
  border: 1px solid #005edc !important;
}
.float {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-color: #fff !important;

  z-index: 1000 !important;
}
.float label {
  color: black !important;
  margin-bottom: 2px !important;
}
.float .field {
  margin-bottom: 1.2em !important;
}

#loading {
  background: url("../assets/logo.gif") no-repeat center center;
  position: absolute;
  top: 200;
  left: 0;
  height: 90%;
  width: 100%;
  z-index: 9999999;
  opacity: 0.5;
  margin-top: 50px;
}
#loading1 {
  background: url("../assets/hour.gif") no-repeat center center;
  position: absolute;
  top: 200;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  opacity: 0.5;
  margin-top: 50px;
  background-size: contain;
}
#darkloading {
  background: url("../assets/logo_dark.gif") no-repeat center center;
  position: absolute;
  top: 200;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  opacity: 0.5;
  margin-top: 50px;
}
.loadingtext {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
}
b {
  font-size: 13px;
}
.heatmap text {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.recharts-text {
  font-size: 15px !important;
}

.recharts-cartesian-axis-tick-value tspan {
  font-size: 13px !important;
}

.ui.fullscreen.scrolling.modal {
  left: 5px !important;
  right: 5px !important;
}
.ui.fullscreen.modal {
  width: 99% !important;
}
.recharts-cartesian-axis-tick-line {
  display: none !important;
}

.ui.fullscreen.modal > .header {
  background-color: #18202f !important;
  color: white !important;
}

.ui.fullscreen.modal > .close {
  right: -3.5rem !important;
  color: white !important;
}

@media only screen and (min-width: 1024px) {
  #viewDiv {
    height: 70vh !important;
  }
}
@media only screen and (min-width: 1024px) {
  #viewDiv2 {
    height: 48vh !important;
  }
}
td {
  font-size: 15px !important;
}

.VictoryContainer svg text tspan {
  font-size: 16px !important;
}

.recharts-cartesian-axis-tick-value tspan {
  font-size: 0.8rem !important;
}

@media only screen and (max-width: 1023px) {
  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.recharts-cartesian-axis-line {
  stroke: none !important;
}

.custom {
  transform: scaleX(1) !important;
  fill: red !important;
}
.circle svg {
  margin-left: 15% !important;
}

.small svg {
  width: 50px !important;
  height: 50px !important;
}
.small p {
  text-align: left !important;
}
.small div p {
  text-align: left !important;
}

.risk {
  display: flex !important;
}
.Progress {
  padding-left: 3% !important;
  padding-right: 7% !important;
  padding-bottom: 4% !important;
}
.Progress p {
  margin-bottom: 0em !important;
}

.ui.five.column.table td {
  width: auto !important;
}
.ui.table thead th {
  font-size: 1rem !important;
  text-align: center;
}
.nav-tabs .nav-link.active {
  border: 2px solid #18202f !important;

  padding-left: 1em !important;
  padding-right: 1em !important;

  display: inline-flex !important;
}

.nav-tabs .nav-link:hover {
  border-bottom: 2px solid #18202f !important ;
}

.nav-link {
  display: inline-flex;
  margin-right: 10%;
}

#index div g {
  padding-left: 10px !important;
}
#index div g rect {
  padding: 20px !important;
}
.Chronic:nth-child(1) .bar {
}
.ant-table {
  color: none !important;
  background-color: #ffffff !important;
  width: 100% !important;
}
.ant-table-cell {
  background-color: #ffffff !important;
}
.ant-table th {
  text-align: center !important;
  font-size: 16px !important;
  background-color: #ffffff !important;
}

.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: #ffffff !important;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #ffffff !important;
}
code {
  color: #18202f !important;
  text-align: center !important;
}
code p {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}

h1 {
  width: 100% !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  padding: 5px !important;
}
.tcfd p {
  font-size: 14px !important;
  padding: 10px !important;
}
.tcfd .column {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
hr {
  border-top: 2px solid black !important;
  width: 95% !important;
}
.circle tspan {
  display: none !important;
}
.disc input {
  border-radius: 0px !important;
  line-height: 4 !important;
  width: 90% !important;
}

.tcfdtable .row {
  padding-bottom: 0px !important;
}
.tcfdtable hr {
  width: 48.5% !important;
  margin-bottom: 0.2rem !important;
  margin-top: 0.1rem !important;
}
.tcfdtable h1 {
  color: white !important;
}
.MuiStepLabel-label {
  text-align: left;
  margin-bottom: 2% !important;
}
.MuiPaper-root {
  width: 80% !important;
}
.tcfdtable textarea {
  width: 80% !important;
}
.report div .row {
  padding-left: 10% !important;
  padding-right: 10% !important;
  height: 900px !important;
}
.report p,
h1,
h2 {
  padding-left: 0% !important;
  padding-right: 0% !important;
}
.report p {
  font-size: 14px !important;
}
.report h1,
.report h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
  padding-top: 2% !important;

  margin: 0em !important;
}
.report .bar {
  height: 400px !important;
}
.pagebreak {
  background-color: #ecf0f3;
  height: 20px !important;
}
.report h1 img {
  height: 35px !important;
}
.report h1 {
  width: "100%";
}

text {
  font-size: 1rem !important;
}
.circle text {
  font-size: 4rem !important;
}
.yeardonut .recharts-wrapper {
  width: 100% !important;
}
.yeardonut .recharts-wrapper svg {
  width: 100% !important;
}

#margin {
  margin-left: 2.75% !important;
}
p {
  font-size: 15px;
}
.dark-mode-theme-color-1 {
  font-size: 1.3rem !important;
}

.graph line {
  display: none !important;
}

.ant-table-cell .ui.button {
  background-color: #e0e1e2 !important;
}
.ant-tootip-open {
  margin-bottom: 0em !important;
}
.marginhead h5 {
  margin: 0em !important;
}
.card {
  margin-left: 0% !important;
}
.legend p {
  display: flex !important;
  font-size: 1rem;
}
[class*="col-lg"] {
  padding: 1% !important;
}

@media (min-width: 1024px) {
  #container-fluid {
    background-color: #ffffff !important;
  }
}
.shadow {
  margin-left: 2.5% !important;

  padding: 2% !important;
}

.spread {
  margin-left: 38% !important;
}

.container-fluid .row {
  margin-left: 0px;
  margin-right: 0px;
}
b {
  font-weight: bold !important;
}
line {
}
.mainheading {
  /*background: linear-gradient(
    0deg,
    rgba(0, 24, 67, 1) 0%,
    rgba(0, 32, 96, 1) 60%
  ) !important;*/
  color: black !important;
  padding: 1em !important;
  margin-bottom: 0.5em !important;
  height: 50px !important;
  border-radius: 20px;
}
.mainheading p {
  width: 100% !important;
}
.Legend p {
  display: flex !important;
  vertical-align: middle !important;
  margin-top: 10% !important;
}
.progress {
  height: 0.35rem !important;
}

.ant-layout-sider-zero-width-trigger {
  position: absolute !important;
  top: -8.5% !important;
  z-index: 300 !important;
  background: #18202f !important;
}

.marginhead h5 span {
  padding-left: 10px !important;
}
.firstshadowmap b span {
  font-size: 14px !important;
}

:fullscreen {
  background-color: white;
}

.severity .progress {
  height: 1rem !important;
}
.ui.blue.progress .bar {
  background-color: #2185d0 !important;
}
.ui.violet.progress .bar {
  background-color: violet !important;
}
.ui.purple.progress .bar {
  background-color: purple !important;
}
.ui.red.progress .bar {
  background-color: red !important;
}
.map-container1 {
  display: block !important;
  height: 450px !important;
  width: 100% !important;
}
.map-container2 {
  width: 100% !important;
  height: 500px !important;
}
.mapboxgl-canvas-container {
  width: "auto" !important;
}
.mapboxgl-canvas-container canvas {
  width: "auto" !important;
}

.mapboxgl-popup-content table td {
  font-size: 11px !important;
  padding: 0px !important;
  border: 1px solid black !important;
}
.mapboxgl-popup-content table {
  width: 100% !important;
  margin-left: 0px !important;
}
.mapboxgl-popup-content #text {
  display: none !important;
}

#risklist a {
  padding: 10px !important;
}
#worldlist a {
  padding: 10px !important;
}
#summarylist a {
  padding: 10px !important;
}
.worldtitle {
  padding: 10px !important;
}

.mapboxgl-popup {
  z-index: 1 !important;
}

.fillerdiv {
  height: 140px;
}

.circle_mt {
  text-align: center;
  margin-left: 25%;
  width: 250px;
  height: 250px;
  background-color: #18202f;
  border-radius: 50%;
  padding-top: 20%;
}

.text_mt {
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
}

.circle_2 {
  margin-top: -558px;
  margin-left: 8%;
  align-items: center;
  width: 550px;
  height: 550px;
  background-color: #f7f7f7;
  border-radius: 50%;
}

.circle_3 {
  margin-top: 10%;
  width: 650px;
  height: 650px;
  background-color: #f2f2f2;
  border-radius: 50%;
}

/*Tooltip*/

.mytooltip {
  display: inline;
  position: relative;
  z-index: 999;
}

.mytooltip .tooltip-item {
  background: transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 0 10px;
}

.mytooltip .tooltip-content {
  position: absolute;
  z-index: 9999;
  width: 360px;
  left: 90%;
  margin: 0 0 20px -180px;
  bottom: 100%;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 8px 8px 18px #f2f2f2, -8px -8px 18px #ffffff;
  opacity: 0;
  cursor: default;
  pointer-events: none;
  height: 300px;
  width: 600px;
  margin-left: 300px;
  margin-bottom: -180px;
  align-items: center;
}

.mytooltip .tooltip-content::after {
  content: "";
  top: 100%;
  left: 50%;
  right: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #2a3035 transparent transparent;
  border-width: 10px;
  margin-left: -10px;
}

.mytooltip .tooltip-content img {
  position: relative;
  height: 140px;
  display: block;
  float: left;
  margin-right: 1em;
}

.mytooltip .tooltip-item::after {
  content: "";
  position: absolute;
  width: 360px;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.mytooltip:hover .tooltip-item::after {
  pointer-events: auto;
}

.mytooltip:hover .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
}

.mytooltip:hover .tooltip-content2 {
  opacity: 1;
  font-size: 18px;
}

.mytooltip .tooltip-text {
  font-size: 18px;
  line-height: 24px;
  display: block;
  padding: 1.31em 1.21em 1.21em 0;
  color: #000;
  text-align: center;
}

.note-hazard {
  font-size: 30px;
}

.parent_div {
  margin-top: -70px;
}

.windimg {
  margin-top: 80px;
}

.rainfallimg {
  margin-right: 340px;
  margin-top: 40px;
}

.fireimg {
  margin-left: 340px;
  margin-top: -100px;
}

.stormimg {
  margin-right: 450px;
  margin-top: 60px;
}

.droughtimg {
  margin-left: 450px;
  margin-top: -120px;
}

.floodimg {
  margin-right: 250px;
  margin-top: 60px;
}

.heatimg {
  margin-left: 250px;
  margin-top: -100px;
}
.mapboxgl-popup-content {
  width: 240px !important;
}
.mapboxgl-popup-content h5 {
  background: red !important;
  color: black !important;
  font-weight: 600 !important;
}
.mapboxgl-popup-content tr:first-child {
  color: red !important;
}

.summary-div1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 1%;
  text-align: center;
  color: #18202f;
  margin-top: 5%;
}
.summary-climate-risk {
  position: relative;
  transition: box-shadow 0.2s;
}
.heading1-summary {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  background: linear-gradient(0deg, #18202f, #001847);
  color: #fff;
  border-radius: 3px;
  padding: 11.5px;
}
.heading1-summary2 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  background: linear-gradient(0deg, #18202f, #001847);
  color: #fff;
  border-radius: 3px;
  padding: 11.5px;
  margin-top: 5% !important;
}
.heading1-summary3 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  background: linear-gradient(0deg, #18202f, #001847);
  color: #fff;
  border-radius: 3px;
  padding: 11.5px;
  margin-top: 5% !important;
}
.para1-summary-rm {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.8;
}
.para1-summary {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.8;
}
.para1-summary-1 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.8;
  margin-left: 40%;
  position: absolute;
  margin-top: -30%;
  margin-right: 4%;
  transition: box-shadow 0.2s;
  padding: 1%;
}
.headingarticle {
  font-size: 35px;
  font-family: "Volkhov", serif;
  line-height: 1;
  margin-top: 2% !important;
}
.articlepara1 {
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}
.articlepara2 {
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}
.articlepara3 {
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}
.articlepara4 {
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}
.btnarticle1 {
  background-color: #fff !important;
  border: 1px solid dimgrey !important;
}
.btnarticle2 {
  background-color: #fff !important;
  border: 1px solid dimgrey !important;
}
.btnarticle3 {
  background-color: #fff !important;
  border: 1px solid dimgrey !important;
}
.summary-intensity {
  position: relative;
  transition: box-shadow 0.2s;
}
.para2-summary {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.8;
}
.col1 {
  transition: box-shadow 0.2s;
}
.worldmap {
  transition: box-shadow 0.2s;
}
.para3-summary {
  font-size: 34px;
  font-family: "Lora", serif;
  line-height: 1.8;
  position: absolute;
  margin-right: 4%;
  transition: box-shadow 0.2s;
  padding: 1%;
}
.para4-summary {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}
.divimg {
  width: 600px;
  height: 350px;
  transition: box-shadow 0.2s;
}
.divimg:hover {
  box-shadow: 10px 10px 20px #bebebe;
}
.summary-div2 {
  margin-top: 5%;
}
.para5-summary {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  transition: box-shadow 0.2s;
}
.summary-div3 {
  font-family: "Spartan", sans-serif;
  font-size: 36px;
  background-color: #ffffff;
  color: #000000;
  border-right: 3px solid #18202f;
  line-height: 1.5;
}
.summary-div3:hover {
  color: #18202f;
}
.summary-div4-0 {
  font-family: "Lora", serif;
  font-size: 38px;
  margin-top: 2%;
  position: absolute;
  margin-left: 8%;
  padding-bottom: 5% !important;
}
.summary-div4-1 {
  font-family: "Montserrat", serif;
  font-size: 34px;
  margin-top: 9% !important;
  position: absolute;
  background-color: #18202f;
  color: white;
  height: 70px;
  width: 250px;
  text-align: center;
  padding-top: 6%;
  margin-left: 8%;
}
.summary-div4-2 {
  font-family: "Montserrat", serif;
  font-size: 34px;
  margin-top: 8% !important;
  position: absolute;
  background-color: #18202f;
  color: white;
  height: 70px;
  width: 250px;
  text-align: center;
  padding-top: 6%;
  margin-left: 8%;
}
.summary-div4-3 {
  font-family: "Montserrat", serif;
  font-size: 34px;
  margin-top: 9% !important;
  position: absolute;
  background-color: #18202f;
  color: white;
  height: 70px;
  width: 250px;
  text-align: center;
  padding-top: 6%;
  margin-left: 8%;
}
.summary-div4-4 {
  font-family: "Montserrat", serif;
  font-size: 34px;
  margin-top: 9% !important;
  position: absolute;
  background-color: #18202f;
  color: white;
  height: 70px;
  width: 250px;
  text-align: center;
  padding-top: 6%;
  margin-left: 8%;
}
.para6-summary-1 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  margin-top: 6.1% !important;
  position: relative;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 70px;
  width: 700px;
  background-color: #e8e8ff;
  margin-left: 23.4%;
}
.para6-summary-2 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  margin-top: 4.9% !important;
  position: relative;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 70px;
  width: 700px;
  background-color: #e8e8ff;
  margin-left: 23.4%;
}
.para6-summary-3 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  margin-top: 4.8% !important;
  position: relative;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 1%;
  height: 70px;
  width: 700px;
  background-color: #e8e8ff;
  margin-left: 23.4%;
}
.para6-summary-4 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  margin-top: 4.8% !important;
  position: relative;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 1%;
  height: 70px;
  width: 700px;
  background-color: #e8e8ff;
  margin-left: 23.4%;
}
.para7-summary {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin-top: 6%;
  line-height: 1.8;
}
.mapheading1 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  background: linear-gradient(0deg, #18202f, #001847);
  color: #fff;
  border-radius: 3px;
  padding: 11.5px;
  margin-top: 2% !important;
}
.mapheading2 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  background: linear-gradient(0deg, #18202f, #001847);
  color: #fff;
  border-radius: 3px;
  padding: 11.5px;
  margin-top: 2% !important;
}
.mapheading3 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  background: linear-gradient(0deg, #18202f, #001847);
  color: #fff;
  border-radius: 3px;
  padding: 11.5px;
  margin-top: 2% !important;
}
.divbg {
  position: relative;
}
.imgtext {
  position: absolute;
  color: white;
  font-size: 90px;
  margin-top: -35%;
  font-family: "Volkhov", serif;
  line-height: 1;
  margin-left: 9%;
}
.imgpara {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  color: white;
  margin-top: 29px;
}
.hr1 {
  margin-left: 14%;
  width: 36% !important;
}
.hr2 {
  margin-left: 14%;
  width: 30% !important;
}
.hr3 {
  margin-left: 14%;
  width: 30% !important;
}
.hr4 {
  margin-left: 14%;
  width: 30% !important;
}
.imgtext {
}
.pfname {
  text-transform: uppercase;
}
.ui.fluid.accordion,
.ui.fluid.accordion .accordion {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 3px solid #ffd900 !important;
  background: #ffe869 !important;
  color: #000 !important;
}
.ui.styled.accordion > .title {
  color: #000 !important;
}

.ui.progress {
  margin: 0em 0 2em !important;
}
.lessrisk .ant-progress-bg,
.lessloss .ant-progress-bg {
  background: red !important;
  height: 0.5rem !important;
  opacity: 0.5;
}
.ui.progress.lessrisk {
  margin: 0em 0 0em !important;
  background-color: white !important;
}
.ui.progress.lessloss {
  margin: 0em 0 0em !important;
  background-color: white !important;
}

.Filter .ui.selection.dropdown {
  width: 80% !important;
}

.borderleft_4 {
  box-shadow: 3px -8px 3px -172px #f1f1f1, 5px 4px 12px 1px #ebebeb;

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  background: url("../assets/borderleft_4.png");
  background-size: cover !important;
}
.borderleft_3 {
  box-shadow: 3px -8px 3px -172px #f1f1f1, 5px 4px 12px 1px #ebebeb;

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  background: url("../assets/borderleft_3.png");
  background-size: cover !important;
}
.borderleft_2 {
  box-shadow: 3px -8px 3px -172px #f1f1f1, 5px 4px 12px 1px #ebebeb;

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  background: url("../assets/borderleft_2.png");
  background-size: cover !important;
}
.borderleft_1 {
  box-shadow: 3px -8px 3px -172px #f1f1f1, 5px 4px 12px 1px #ebebeb;

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  background: url("../assets/borderleft_1.png");
  background-size: cover !important;
}
.borderleft .row {
  width: 100% !important;
}

.trpara {
  font-weight: 400;
  color: grey !important;
  font-size: 100% !important;
}

.trpara2 {
  color: grey !important;
}

.trpara2 .row:first-child {
  color: black !important;
}

.key_finding {
  text-align: left !important;
  font-size: 18px !important;
  background-color: #f4f4f4 !important;
  padding: 3%;
  border-radius: 15px;
}

.intense1 {
  border-top: 2px solid #ff8080 !important;
  width: "20%" !important;
}
.intense2 {
  border-top: 2px solid #18202f !important;
  width: "20%" !important;
}
.variable_heading {
  font-weight: bold !important;
}
.realestate-map-container {
  height: 90vh !important;
  width: 100% !important;
  display: block !important;
  margin-top: -2% !important;
}
.location-map-container {
  height: 70vh !important;
  width: 100% !important;
  display: block !important;
}
.advance {
  background: white !important;
}
.Chronic .ant-progress-line {
  width: 100% !important;
}
.ant-progress-line {
  width: 92% !important;
}
.risk_severity .ant-progress-text {
  color: black !important;
}

.severity_single .ant-progress-text {
  color: black !important;
}

#deckgl-wrapper {
  position: relative !important;
}
#view-default-view {
  height: 500px !important;
}

#view-default-view > div {
  height: 500px !important;
}
#deckgl-overlay {
  height: 500px !important;
}
.deck-tooltip table {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.mapboxgl-popup-content {
  border: 1px solid black !important;
}
.worldmarker {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
}

#mapmenu {
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 20px;
  border-radius: 3px;
  width: 120px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;
}

#mapmenu a {
  font-size: 13px;
  color: #404040;
  display: block;
  margin: 0;
  padding: 0;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}

#mapmenu a:last-child {
  border: none;
}

#mapmenu a:hover {
  background-color: #f8f8f8;
  color: #404040;
}

#mapmenu a.active {
  background-color: #3887be;
  color: #ffffff;
}

#mapmenu a.active:hover {
  background: #3074a4;
}

.hazard_legend {
  margin-left: 20% !important;
  margin-top: -1% !important;
}

.dimmed.dimmable > .ui.modals.dimmer.visible {
  z-index: 1050 !important;
}
.breadcrumb-item a {
  white-space: nowrap !important;
  overflow: hidden !important;
  padding: 0% !important;
}
.bars {
  padding-left: 175% !important;
  cursor: pointer !important;
}
.adapt .bars {
  padding-left: 0% !important;
}
.firstshadowheat {
  padding: 0% !important;
  border: 1px solid #e6e6e6;
}

.firstshadowmap {
  margin-left: 4% !important;
  background: #ffffff;
  border: 1px solid #e6e6e6;

  padding: 1% !important;
}

.firstshadow {
  background: #ffffff;
  border: 1px solid #e6e6e6;

  padding: 1% !important;
}
.firstshadow label {
  color: black !important;
}
.firstshadow th,
.irstshadow tr,
.firstshadow td {
  border: none !important;
}
.firstshadow .heading {
  min-height: 0px !important;
}
.firstshadow #worldlistings {
  padding-bottom: 0px !important;
  display: flex !important;
}
.firstshadow .ui.toggle.checkbox label:before {
  background: #fff !important;
}

.firstshadowtable {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 0% !important;
}
.firstshadowtable label {
  color: black !important;
}
.firstshadowtable th,
.irstshadowtable tr,
.firstshadowtable td {
  border: none !important;
}
.firstshadowtable th {
  background: #355ed814 !important;
  color: #18202f !important;
}

.firstshadowtable .heading {
  min-height: 0px !important;
}
.firstshadowtable #worldlistings {
  padding-bottom: 0px !important;
  display: flex !important;
}
.firstshadowtable .ui.toggle.checkbox label:before {
  background: #fff !important;
}
.measures .button {
  float: right !important;
}

.adapt img {
  margin-left: 50% !important;
}

.ant-select-dropdown {
  width: 340px !important;
}

#capture .mapboxgl-popup-close-button {
  margin-right: 2% !important;
  font-size: 16px !important;
}
