h2 {
  color: #18202f;
}

#gettingstarted,
#addasset,
#addbulkassets,
#getanalysis {
  box-shadow: 5px 8px 12px grey;
  width: 96% !important;
}

h1 {
  font-family: "Raleway", sans-serif;
  color: #18202f;
  margin-bottom: 2%;
}

th {
  padding: 1.8%;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 14px;
  background-color: #18202f;
  border: 2px solid #18202f;
}

td {
  border: 1px solid #18202f;
  padding: 10px;
}

tr {
  border: 1px solid #18202f;
  padding: 10px !important;
  text-align: center;
  margin-bottom: 10px;
}

#t01 tr:nth-child(odd) {
  background-color: #ebf0fa;
}
#t01 tr:nth-child(even) {
  background-color: #fff;
}
.GettingStarted {
  padding-top: 1.2% !important;
}

.toolkit img {
  width: 65% !important;
}
