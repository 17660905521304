.dimmed.dimmable > .ui.modals.dimmer.visible {
  display: flex !important;
}

.map {
  height: 500px;
}
.cards {
  height: 400px;
}
.indexcard {
  width: 100px;
  height: 50px;
  text-align: center;
  background-color: #d9d9d9 !important;
  box-shadow: 20px 20px 60px #d9d9d9;
}

.nav-tabs .nav-link {
  margin-right: 0%;
  margin-top: -1.1% !important;
  border-bottom: 3px solid #000000;

  text-align: center;
}

.nav-tabs {
  border-bottom: none !important;
}
@media only screen and (min-width: 1024px) {
  #viewDiv {
    padding: 0;
    margin: 0;
    height: 70vh !important;
    width: 100%;
    margin-top: -3.5% !important;
  }
}

.cont {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px;
}
.cont .card {
  width: 150px !important;
  border: 0px solid grey;
  height: 150px !important;
  transform-style: preserve-3d;
  position: relative;
}
.cont .card:hover {
  transform: rotatey(-180deg) translatey(-20px);
}
.cont .card .front {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 15px;

  z-index: 99999;
  overflow: hidden;
}
.cont .card .front .img-cont {
  width: 100%;
  height: 90px;
  overflow: hidden;
}
.cont .card .front .img-cont img {
  margin-top: 20%;
  margin-left: 32%;
  width: 50px;
  height: 50px;
}
.cont .card .front .content-cont {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 10px;
  font-family: monospace;
}
.cont .card .back {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 15px;
  background-color: white;
  transform: rotatey(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: monospace;
}
.cont .card button {
  padding: 10px 20px;
  background-color: #14274e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}
.cont .card button::after {
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: "";
  background-color: #14274e;
  z-index: -1;
  transition: 0.8s;
}
.cont .card button:hover {
  background-color: #0a1325;
}
.cont .card button:hover::after {
  transform: scale(1.5);
  opacity: 0;
}
@keyframes fade-in-bottom {
  0% {
    transform: translatey(50px);
  }
  100% {
    transform: translatey(0);
  }
}

.ui.menu .item:before {
  width: 0px !important;
}
.portfolio {
  width: 190%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
#form-input-control-portfolio {
  background: #f4f4f4;
  border-radius: 5px;
  box-shadow: none !important;
  height: 50px;
}
label[for="form-input-control-portfolio"] {
  color: #18202f !important;
  font-size: 16px !important;
  font-family: "montserrat" !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #18202f !important;
  border-color: #18202f !important;
}
.asset {
  box-shadow: 0px !important;
  font-size: 20px !important;
  padding: 10px !important;
}
.divider.text {
  color: grey !important;
}

.ui.modal > .close {
  color: #ffffff !important;
}

.ui.modal > .header:not(.ui) {
  font-size: 28px !important;
  font-family: "montserrat" !important;
  color: black !important;
  background: white !important;
}

.drodown.icon {
  font-size: 20px !important;
  margin-top: -20px !important;
}
a[value="Flood"] > i,
a[value="Typhoon"] > i,
a[value="Storm Surge"] > i {
  display: none !important;
}

aside div button {
  box-shadow: none !important;
}
.location .nav-link {
  text-align: center;

  margin-bottom: 0% !important;
  font-size: 14px !important;
  background-color: white !important;

  border-radius: 0px !important;
}
.location .nav-link.active {
  background-color: white !important;
  color: #3462be !important;
  font-weight: normal;
  font-family: "montserrat" !important;
  box-shadow: none;
  border: none !important;
}
.csvbox h3 {
  text-align: center !important;
  color: #18202f !important;
  font-family: "montserrat";
  font-size: 22px;
}
.csvbox .border {
  height: 500px !important;
  overflow-y: auto;
  border-radius: 15px;
  border: 2px solid #18202f !important;
  margin-left: 5%;
  margin-right: 5%;
}

.border .ui.label {
  margin-bottom: 5% !important;
  margin-top: 1% !important;
  margin-left: 6.5% !important;
  margin-right: 6.5% !important;

  font-weight: 510 !important;
}
#video {
  width: 100% !important;
  height: 60% !important;
  padding: 5% !important;
  box-shadow: inset 2px 2px 5px #d1d9e6, inset -3px -3px 7px #f9f9f9 !important;
}
.ant-checkbox-wrapper span {
  color: black !important;
}
.ant-checkbox-wrapper-disabled span {
  color: grey !important;
}

.inputGroup {
  background-color: #fff;
  display: block;
  margin: 15px 15px;
  position: relative;
  padding: 20px;
  box-shadow: 11px 11px 22px #ebebeb, -11px -11px 22px #ffffff;
  border-radius: 20px;
}
.inputGroup label {
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 2px !important;
}
.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.inputGroup label:after {
  width: 20px;
  height: 20px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}
.inputGroup input:checked ~ label {
  color: #fff;
}
.inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #18202f;
  border-color: #18202f;
}
.inputGroup img {
  width: 150px !important;
  height: 70px !important;
}
.inputGroup input {
  width: 100px;
  height: 100px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
.form {
  padding: 0 16px;

  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}
code {
  background-color: #9aa3ac;
  padding: 0 8px;
}
.company_asset .table .table__heading {
  font-weight: 500 !important;
  background-color: #355ed814 !important;
  color: #18202f !important;

  text-transform: uppercase !important;
}
.company_asset .table {
  padding-top: 0% !important;
  overflow-x: auto;
  border-radius: 0px !important;
  border: 1px solid #eaecf0 !important;
}
.company_asset .table__cell:first-child {
  width: 50% !important;
}
.company_asset .table__cell {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  line-height: 95.69%;
  padding: 1% !important;
  letter-spacing: 0.02em;
  width: 25% !important;
}
.company_asset .table__row {
  background-color: white !important;
}

.asset .table .table__heading {
  font-weight: 500 !important;
  background-color: #355ed814 !important;
  color: #18202f !important;

  text-transform: uppercase !important;
}
.asset .table {
  margin-top: 4% !important;
  height: 70vh !important;
  padding-top: 0% !important;
  overflow-x: auto;
  border-radius: 0px !important;
  border: 1px solid #eaecf0 !important;
}
.asset .table__cell:first-child {
  width: 40% !important;
}
.asset .table__cell {
  font-family: "Montserrat";
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-size: 12px;

  padding-bottom: 0.5% !important;
  padding-top: 0.5% !important;
  letter-spacing: 0.02em;
  width: 15% !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: white !important;
  color: #18202f !important;
}

.fields {
  width: 90%;
  margin-left: 4% !important;
  margin-top: 2% !important;
}

.transition-label {
  color: #000000 !important;
  font-size: 15px !important;
}

.transition-ip {
  background-color: transparent !important;
  box-shadow: 5px 5px 6px #dbdbdb, -5px -5px 6px #ffffff !important;
  width: 40% !important;
}

.transition-ip-nav {
  background-color: transparent !important;
  box-shadow: 5px 5px 6px #dbdbdb, -5px -5px 6px #ffffff !important;
  width: 13% !important;
}

.no-project {
  margin-left: 9%;
  margin-top: 10%;
}

.projectpara1 {
  font-size: 50px;
  font-family: "Volkhov", serif;
  color: #18202f;
  position: relative;
}

.errorpng {
  position: absolute;
  margin-left: 40%;
  margin-top: -18%;
  width: 20%;
}

.transition .ant-table th {
  background-color: #18202f !important;
  color: white !important;
  font-family: "Montserrat", sans-serif;
}

.sbtitable .ui.selection.dropdown {
  border: 0.5px solid black !important;
}
.new_transition {
  box-shadow: 20px 20px 60px #d9d9d9, -20px 20px 60px #ffffff !important;
  border-radius: 15px !important;
  margin-top: 3%;
}

.seacrcmp {
  font-size: 28px;
  font-family: "montserrat";
}

.dropdwnc1 {
  width: 80% !important;
  margin-left: 0.1% !important;
}

.dropdwnc2 {
  border: none !important;
  height: 40px !important;
}

.submitbtn {
  height: 3.5em !important;
  width: 8em !important;
}

.submitbtn2 {
  height: 3.5em !important;
  width: 8em !important;
  background-color: #ededed !important;
}

.companypage {
  margin-left: 1.2% !important;
}

#mineaddress {
  font-size: 24px !important;
  font-family: "montserrat";
}

.mineaddressrow {
  margin-top: 5%;
  margin-left: 2% !important;
  width: 90%;
}

#mineaddressbtn {
  width: 130px;
  margin-left: 2%;
  height: 50px;
  margin-top: 2%;
  background-color: #18202f;
  box-shadow: none !important;
  border-radius: 5px;
}

.indexlctn {
  margin-left: 5% !important;
}

.assetcsv {
}

.h3assetcsv {
  font-size: 20px;
  font-family: "montserrat";
}

.samplecsv {
  position: absolute;
  margin-left: 2% !important;
  background-color: rgb(246, 248, 250) !important;
  color: #18202f !important;
  border: 0.5px solid #18202f !important;
  border-radius: 6px !important;
}

.uploadcsv {
  background-color: #18202f !important;
  float: right !important;
}

.variable_title {
  font-size: 30px;
  font-weight: 700;
  color: #292d3f;
  text-align: center;
  margin-bottom: 50px;
}

.variable_card_area {
  position: relative;
  margin-bottom: 30px;
}
.single_variable_card {
  border: 1px solid #efefef;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.variable_card_title {
  padding: 5px;
  text-align: center;
  background-color: #d6d6d6;
}
.variable_card_title h3 {
  font-size: 14px;
  font-weight: 400;
  color: #292d3f;
  margin-bottom: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.variable_card_title h3 i {
  opacity: 0;
}
.variable_card_icon {
  max-width: 100%;
  min-height: 85px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.variable_icon {
  width: 40px;
  height: 45px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.variable_icon1 {
  background-image: url("../assets/icon_rfl.png");
}
.variable_icon2 {
  background-image: url("../assets/icon_storm.png");
}
.variable_icon3 {
  background-image: url("../assets/icon_typhoon.png");
}
.variable_icon4 {
  background-image: url("../assets/icon_wildfire.png");
}
.variable_icon5 {
  background-image: url("../assets/icon_landslide.png");
}
.variable_icon6 {
  background-image: url("../assets/icon_rainfall.png");
}
.variable_icon7 {
  background-image: url("../assets/icon_heat.png");
}
.variable_icon8 {
  background-image: url("../assets/icon_snowfall.png");
}
.variable_icon9 {
  background-image: url("../assets/icon_slr.png");
}
.variable_icon10 {
  background-image: url("../assets/icon_drought.png");
}
.variable_icon11 {
  background-image: url("../assets/icon_solar.png");
}
.variable_icon12 {
  background-image: url("../assets/icon_wind.png");
}
.variable_icon13 {
  background-image: url("../assets/icon_hydro.png");
}
.variable_icon14 {
  background-image: url("../assets/icon_wheat.png");
}
.variable_icon15 {
  background-image: url("../assets/icon_fruits.png");
}
.variable_icon16 {
  background-image: url("../assets/icon_vegetable.png");
}
.variable_icon17 {
  background-image: url("../assets/icon_corn.png");
}

.ant-checkbox-wrapper-checked ~ .single_variable_card {
  border: 1px solid black !important;
}
.ant-checkbox-wrapper-checked:hover ~ .single_variable_card {
  border: 1px solid black !important;
}

.ant-checkbox-wrapper-checked
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title {
  background-color: #18202f !important;
  color: white !important;
}
.ant-checkbox-wrapper-checked
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title
  h3 {
  color: white !important;
}
.ant-checkbox-wrapper-checked
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title
  h3
  i {
  opacity: 1 !important;
}
.ant-checkbox-wrapper-checked:hover ~ .variable_card_title {
  border: 1px solid black !important;
}

/*Icon Selector*/

.ant-checkbox-wrapper-checked
  ~ .single_variable_card
  .variable_card_content
  .variable_card_icon {
  color: #18202f !important;
}

.variable_card_icon {
  font-size: 50px;
  color: #000000;
}

.ant-checkbox-group {
  width: 100% !important;
}

.ant-checkbox-wrapper-disabled ~ .single_variable_card {
  border: 1px solid #18202f !important;
}
.ant-checkbox-wrapper-disabled:hover ~ .single_variable_card {
  border: 1px solid #18202f !important;
}

.ant-checkbox-wrapper-disabled
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title {
  background-color: #18202f !important;
  color: #ffffff !important;
}
.ant-checkbox-wrapper-disabled
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title
  h3 {
  color: #ffffff !important;
}
.ant-checkbox-wrapper-disabled
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title
  h3
  i {
  opacity: 1 !important;
}
.ant-checkbox-wrapper-disabled:hover ~ .variable_card_title {
  border: 1px solid #18202f !important;
}

/*Icon Selector*/

.ant-checkbox-wrapper-disabled
  ~ .single_variable_card
  .variable_card_content
  .variable_card_icon {
  color: #18202f !important;
}

.variable_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
}
.group-wrapper .ant-checkbox-group {
  width: 100% !important;
}

.toppara {
  font-size: 26px !important;
  font-family: "montserrat";
  font-weight: 500;
}

.mapboxgl-marker {
  width: 25px !important;
  height: 25px !important;
  background-size: cover;
}

.mapboxgl-ctrl-geocoder--pin-right {
  display: none !important;
}
.mapboxgl-ctrl-geocoder--input {
  border-radius: 0px !important;
  font-size: 15px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.mapcol {
  padding: 0% !important;
}

.suggestions-wrapper {
  margin-left: 7.5%;
}
.suggestions {
  background-color: white !important;
  padding: 1% !important;
}
#toast-container > div {
  opacity: 1 !important;
  background-color: rgb(255, 171, 0) !important;
  box-shadow: none !important;
}

.dataset-container {
  border-radius: 8px;
}

.dataset-label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.select-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.select-item {
  display: flex;
  flex-direction: column;
}

.select-dropdown {
  width: 100%;
}
