.topnav {
  color: #ffffff !important;
  text-align: center !important;
  font-family: Montserrat !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
  letter-spacing: 0.1375rem !important;
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

.navbar-expand-sm .navbar-nav .dropdown-menu {
  padding: 2em !important;
  border-radius: 3% !important;
  font-family: Montserrat !important;
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

.navbar-nav .nav-item a {
  font-family: Montserrat !important;
  font-size: 1.2rem !important;
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

.navbar-nav .nav-link {
  color: #ffffff !important;
  transition: none !important;
  transform: none !important;
}
