.item {
  background: #ededed;
  text-align: center;
  height: 7em;
}

.know_container h1 {
  width: 30% !important;
  border-bottom: 1px solid black !important;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 3.125rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.know_container .col {
  width: 90% !important;
  border-radius: 0.3125rem !important;
  background: rgba(8, 23, 53, 0.1) !important;
  padding: 3% !important;
}
.know_container h2 {
  margin-left: 15% !important;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 2.4rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.know_container p {
  margin-left: 15% !important;
  width: 70% !important;
  color: #000;
  font-family: Montserrat;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 134.9%;
}

.landingmap_container h1 {
  width: 30% !important;
  border-bottom: 1px solid black !important;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 3.125rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.landingmap_container img {
  width: 80% !important;
}
.news_container h1 {
  width: 30% !important;
  border-bottom: 1px solid black !important;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 3.125rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.news_container img {
  width: 100% !important;
  padding: 2% !important;
  height: 30% !important;
  border-radius: 1.3rem !important;
}
.news_container h2 {
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 1.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.0375rem !important;
  padding: 2% !important;
}

.news_container p {
  padding: 2% !important;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 1.3rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 139.4% !important;
  letter-spacing: 0.03rem !important;
}
.events_container h1 {
  width: 30% !important;
  border-bottom: 1px solid black !important;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 3.125rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.events_container img {
  border-radius: 0.625rem;
  width: 100% !important;
  padding: 5% !important;
  height: 30% !important;
}
.events_container h2 {
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 1.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.0375rem !important;
  padding: 2% !important;
}

.events_container p {
  padding: 2% !important;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 1.3rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 139.4% !important;
  letter-spacing: 0.03rem !important;
}

.events_container .col:nth-child(2) img {
  transform: scale(1.2) !important;
}

.breadcrumb-item a {
  color: #fefefe;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.225rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.1125rem;
}
