#tritab1 {
  font-weight: bold;
  background-color: transparent;
  color: #18202f !important;
  pointer-events: none;
  margin-top: 1% !important;
  border: 1px solid #18202f;
}

#tritab2 {
  background-color: transparent;
  color: white !important;
  pointer-events: none;
}

#subtab1 {
  border-bottom: 0.5px solid #18202f !important;
  color: #3462be !important;
  background-color: transparent !important;
  padding: 0.8%;
  padding-left: 2%;
  padding-right: 2%;
  font-family: "Montserrat";
  font-weight: 600;
  border-radius: 5px !important;
  margin-left: 1.5%;
  margin-top: 1% !important;
}

#subtab2 {
  border-bottom: 0.5px solid #18202f !important;
  color: #3462be !important;
  background-color: transparent !important;
  padding: 0.8%;
  padding-left: 2%;
  padding-right: 2%;
  font-family: "Montserrat";
  margin-left: 2%;
  font-weight: 600;
  border-radius: 5px !important;
  margin-top: 1% !important;
}

.head1 {
  font-family: "montserrat";
  font-size: 20px;
  font-weight: lighter !important;
  margin-left: 1% !important;
  margin-top: 2% !important;
  position: absolute;
}

.form-control {
  background: #dbe7ff !important;
  border-radius: 20px !important;
  box-shadow: none !important;
}

.hr100 {
  position: absolute;
  width: 90% !important;
}

.trparanote {
  justify-self: center;
  font-size: 16px !important;
  border: 3px solid #18202f;
  border-radius: 10px;
  padding: 2%;
}

.upcsv {
  width: 225px;
  height: 135px;
  font-size: 16px !important;
  margin-right: 4% !important;
  background: #ffffff !important;
  border: 2px solid #18202f !important;
  border-radius: 15px !important;
  margin-left: 2% !important;
}

#fileslct {
  background: white !important;
  box-shadow: none !important;
  border: 3px solid #18202f !important;
  border-style: dashed !important;

  border-radius: 15px !important;
  height: 200px !important;
  justify-items: center !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  text-align: center !important;
}

#fileslct > title {
  background: black !important;
}

.forminpt {
  margin-top: -20%;
  margin-left: 35%;
  margin-bottom: 2%;
  max-height: 200px !important;
}

#forminpt2 {
  box-shadow: none !important;
  height: 60px !important;
  background: #ecf2ff !important;
  border-radius: 10px !important;
  color: #18202f !important;
  font-size: 20px !important;
}

#forminpt3 {
  box-shadow: none !important;
  height: 60px !important;
  background: #ecf2ff !important;
  border-radius: 10px !important;
  color: #18202f !important;
  font-size: 20px !important;
}
