.rowbtn {
  background-color: white !important;
  border: 2px solid #18202f !important;
  width: 120px;
  height: 40px !important;
  border-radius: 5px !important;
  color: #18202f !important;
  position: relative;
  margin-top: -2%;
  margin-left: 40%;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: none !important;
}

.transitiontable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  background-color: #eeeeee !important;
  color: #000000 !important;
  border-color: transparent !important;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0.2% !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  background-color: #ffffff !important;
}

.transitiontable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th:nth-child(even) {
  background-color: #eeeeee !important;
}

.ant-table-tbody > tr > td {
  border-color: transparent;
  border-bottom: 1px solid black;
}
