.card {
  background-color: #fff !important;
  border: . 5% solid rgb(243, 247, 250, 0.05) !important;
  border-radius: 1%;
  padding: 2%;
  margin-left: 10%;
}

.topbar {
  overflow: hidden;
  background-color: #fff;
  margin-top: 1%;
  margin-left: 1%;
}

.topbar a {
  float: left;
  color: #111;
  text-align: center;
  padding: 1% 1.5%;
  text-decoration: none;
  font-size: 1.2rem;
}

.topbar a:hover {
  border-bottom: 3px solid #18202f;
  color: black;
}

.topbar a.active {
  background-color: #18202f;
  border-bottom: 3px solid #18202f;
  color: white;
}

//*-----Accordion-------*//

.accordion {
  border: none !important;
  width: 95%;
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  margin-top: 1%;
  font-size: 15px;
}

.accordion__button {
  background-color: #f4f4f4;
  color: #18202f !important;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  font-size: 16px;
}

.accordion__button:hover {
  background-color: #ebf0fa !important;
}

.accordion__button:before {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.5s ease-in;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.search-col {
  display: flex;
  align-items: center;
}

.search-col .form-control {
  border-radius: 0 !important; /* Set border radius to none */
  width: 100% !important;
}
.bg-light {
  background-color: white !important;
}
.navbar-light .navbar-nav .nav-link {
  color: grey !important;
}
