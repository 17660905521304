:root {
  --intensel-blue: #18202f;
  --intensel-orange: #18202f;
}

.intenselBlue {
  color: var(--intensel-blue);
}

.intenselOrange {
  color: var(--intensel-orange);
}

.navbar {
  box-shadow: none !important;
}

.navlink {
  color: black !important;
  font-weight: bold;
  margin: auto;
  z-index: 10;
}

.navbar button,
.navbar button:hover {
  border-radius: 10px;
  color: white;
  border-color: var(--intensel-orange);
  background-color: var(--intensel-orange);
  font-weight: bold;
  font-size: inherit;
  padding: 0 10px 0 10px;
  height: 50px;
}

.signInButton {
  padding: 10px 45px;
  font-size: 17px;
  margin-right: 30px;
  color: white;
  border: 4px solid var(--intensel-orange) !important;
  background-color: var(--intensel-orange);
}

.signUpButton {
  padding: 10px 45px;
  font-size: 17px;

  border: 4px solid var(--intensel-orange) !important;
  background-color: #ffffff;
}

.hero {
  top: 95px;
  left: 130px;
  width: 500px;
  position: absolute;
}

.heroLeft {
  top: 60px;
  left: 90px;
  width: 150px;
  z-index: -1;
  position: absolute;
}

.heroRight {
  top: -80px;
  left: 500px;
  width: 100px;
  z-index: -1;
  position: absolute;
}

@media only screen and (max-width: 991px) {
  .hero {
    left: auto;
    position: relative;
  }

  .heroLeft,
  .heroRight {
    display: none;
  }
}

.sectionBody {
  font-size: 19px;
  margin-bottom: 80px;
}

.customers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--intensel-orange);
}

.customers > li {
  list-style-type: none;
}

@media only screen and (max-width: 991px) {
  .customers {
    flex-wrap: wrap;
  }

  .customers > li {
    flex: 0 50%;
    margin-top: 20px;
  }
}

.customers > li > img {
  width: 35px;
  margin-right: 5px;
}

.whyIntensel {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.whyIntensel :global(.card) {
  width: 20%;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 300px -48px rgba(0, 0, 255, 0.2);
}

:global(.card) {
  border: none;
}

@media only screen and (max-width: 991px) {
  .whyIntensel :global(.card) {
    width: 46%;
    margin-bottom: 15px;
  }
}

.whyIntensel :global(.card-title),
.whyIntensel :global(.card-text) {
  font-size: 22px;
  font-weight: bold;
}

.whyIntenselCardTitleOdd,
.whyIntenselCardTitleEven {
  width: 1.5em;
  height: 1.5em;
  line-height: calc(1.5em - 8px);
  text-align: center;
  border-radius: 3px;
  border: 4px solid var(--intensel-orange);
}

.whyIntenselCardTitleOdd {
  color: white;
  background-color: var(--intensel-orange);
}

.whyIntenselCardTitleEven {
  color: var(--intensel-orange);
}

.whyIntenselAdvantages {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.whyIntenselAdvantages :global(.card) {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid var(--intensel-orange);
  padding: 0;
  width: 30%;
}

.whyIntenselAdvantages :global(.card-title) {
  background-color: var(--intensel-orange);
  color: white !important;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  text-align: center;
}

.whyIntenselAdvantages .subtitle {
  font-weight: bold;
  font-size: 16px !important;
  margin-bottom: 0;
}

.whyIntenselAdvantages .advantageText {
  font-size: 12px;
  margin-bottom: 28px;
}

.whyIntenselDataSources {
  margin-bottom: 40px;
}

.whyIntenselDataSources > ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.whyIntenselDataSourcesItem {
  width: 20%;
  margin: 0 !important;
  margin-top: 30px !important;
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  .whyIntenselDataSourcesItem {
    width: 40%;
  }
}

.whyIntenselDataSourcesItem > div {
  height: 100px;
}

.whyIntenselDataSourcesItem li {
  list-style-type: initial;
  list-style-position: inside;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 15px;
}

.whyIntenselSimulationOfFuturePerils > ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.whyIntenselSimulationOfFuturePerils > ul > li:before {
  content: "";
  background: #000000;
  border-radius: 10px;
  position: absolute;
  width: 4px;
  height: 29px;
  transform: translateX(-23px);
}

@media only screen and (max-width: 767px) {
  .whyIntenselSimulationOfFuturePerils > ul {
    font-size: 12.5px;
  }
  .whyIntenselSimulationOfFuturePerils > ul > li:before {
    content: "";
    background: #000000;
    border-radius: 10px;
    position: absolute;
    width: 4px;
    height: 20px;
    transform: translateX(-17px) translateY(4px);
  }
}

.text {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.text > p {
  font-size: 19px;
}
.feature {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
}

.feature :global(.card) {
  width: 30%;
  box-shadow: 0px 0px 300px -48px rgba(0, 0, 255, 0.1);
  margin-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .feature :global(.card) {
    width: 45%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .feature :global(.card) {
    width: 98%;
  }
}

.feature :global(.card-title) {
  color: var(--intensel-black);
}

.feature :global(.card-text) ul {
  width: 100%;
  text-align: left;
  margin-top: 30px;
}

.feature :global(.card-text) li {
  padding-left: 2em;
  margin-bottom: 10px;
}

.feature :global(.card-text) li:before {
  color: var(--intensel-blue);
  content: "\e096";
  font-family: simple-line-icons;
  display: inline-block;
  margin-left: -2em;
  width: 2em;
}

.feature :global(.btn),
.features :global(.btn):hover {
  float: right;
  color: white;
  font-weight: bold;
  border-color: var(--intensel-orange);
  background-color: var(--intensel-orange);
  border-radius: 10px;
  padding: 15px 30px 15px 30px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
}

.features :global(.card) {
  width: 43%;
  box-shadow: 0px 0px 300px -48px rgba(0, 0, 255, 0.1);
  margin-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .features :global(.card) {
    width: 45%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .features :global(.card) {
    width: 98%;
  }
}

.features :global(.card-title) {
  color: var(--intensel-blue);
}

.features :global(.card-text) ul {
  width: 100%;
  text-align: left;
  margin-top: 30px;
}

.features :global(.card-text) li {
  padding-left: 2em;
  margin-bottom: 10px;
}

.features :global(.card-text) li:before {
  color: var(--intensel-blue);
  content: "\e096";
  font-family: simple-line-icons;
  display: inline-block;
  margin-left: -2em;
  width: 2em;
}

.features :global(.btn),
.features :global(.btn):hover {
  float: right;
  color: black;
  font-weight: bold;
  border-color: var(--intensel-orange);
  background-color: var(--intensel-orange);
  border-radius: 10px;
  padding: 15px 30px 15px 30px;
}

.intenselSaasAdvantage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: center;
  background-image: url("../assets/images/saas-advantage-pacific-map.png");
  background-size: cover;
}

.intenselSaasAdvantage > div {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .intenselSaasAdvantage > div {
    width: 100%;
  }
  .intenselSaasAdvantage > div:nth-child(2) {
    margin-bottom: 100px;
  }
}

.intenselSaasAdvantageItem {
  display: grid;
  grid-template-columns: 100px auto;
}

.intenselSaasAdvantageItem > img {
  place-self: center;
}

.intenselSaasAdvantageItem > p {
  font-size: 20px;
  font-weight: normal;
  place-self: center;
}

.climateRiskItem {
  display: grid;
  grid-template-columns: 150px auto;
  grid-template-rows: 150px auto;
  margin-bottom: 50px;
}

.climateRiskItemDescription {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  margin-left: 40px;
  font-size: 20px;
}

.climateRiskItemDescription > a {
  margin-top: 5px;
  color: var(--intensel-orange);
  text-decoration: underline;
  text-underline-offset: 6px;
}

.climateRiskItemName {
  text-align: center;
  font-weight: bold;
  font-size: 19px;
  margin-top: 3px;
}

footer {
  min-height: 100px;
  background-color: var(--intensel-blue);
  color: white;
  display: flex !important;
  align-items: center;
}

footer > div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

footer > div > div {
  display: inline-block;
}

footer i {
  font-size: 39px;
  margin-right: 17px;
}

@media only screen and (max-width: 767px) {
  footer {
    font-size: 12px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  footer i {
    font-size: 24px;
    margin-right: 10px;
  }

  footer > div > div {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

footer > div > div a,
footer > div > div a:hover {
  color: unset;
}
