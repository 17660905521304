/* MyTable.css */

.table-container {
  margin: 20px !important;
}
.custom-table {
  padding: 1% !important;
}

.custom-table .ant-table-thead > tr > th {
  background-color: #eeeeee !important;
  color: #000000 !important;
  border-color: transparent !important;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0.2% !important;
}
.hide.ant-switch-checked {
  margin-right: 0% !important;
}
.hide {
  margin-right: 0% !important;
}

.legend {
  position: absolute;
  bottom: 30px;
  right: 10px; /* Change this to 'right' */
  background: white;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
  width: 150px;
  opacity: 0.75;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-color {
  width: 30px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
}

.legend-label {
  font-size: 12px;
}

#capture .mapboxgl-popup-content {
  opacity: 0.8 !important;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out;
}
.double-bounce2 {
  animation-delay: -1s;
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.mapcol {
  position: relative;
}

.table-container {
  width: 100%;
  max-width: 300px; /* Adjust size as desired */
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.location-map-container {
  width: 100%;
  height: 500px; /* Adjust height as needed */
}

#MapModule .table-container {
  width: 100%;
  max-width: 100% !important; /* Adjust width as needed */
  margin-bottom: 1rem;
  padding: 0.5rem !important;
  margin: 0px !important;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
#MapModule .table {
  padding: 0rem !important;
  margin: 0rem !important;
}

#MapModule .table__body {
  display: flex;
  flex-direction: column;
}

#MapModule .table__heading {
  font-weight: bold;
  color: white;
  display: flex;
  padding: 0.25rem;
  border-bottom: 1px solid #ddd;
}

#MapModule .table__row {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  border-bottom: 1px solid #eee;
}

#MapModule .table__cell {
  flex: 1;
  padding: 0.5rem;
  color: white;
  text-align: center;
}

#MapModule .table__cell:last-child {
  text-align: right;
}

#portfolioCard {
  padding: 15px;
  background-color: #f9f9f9;
}

#portfolioCard .created_date {
  float: right;
  font-size: 12px;
  font-weight: normal;
}

#portfolioCard .portfolio_labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

#portfolioCard .portfolio_distance {
  font-size: 14px;
  color: #6c757d;
}

#portfolioCard .portfolio_variable {
  font-size: 14px;
  color: #6c757d;
  text-align: right;
}

#portfolioCard .button_row {
  margin-top: 15px;
  text-align: center;
}

#portfolioCard .analyse_button {
  background-color: #355ed814;
  color: #18202f;
  font-size: 16px;
  border-radius: 2px;
  width: 100%;
}

.download-data-btn {
  background-color: #18202f;
  color: white;
  border: 0;

  float: right;
}
.download-map-btn {
  background-color: #18202f !important;
  color: white !important;
  border: 0 !important;
  margin-left: 5% !important;
  float: right !important;
}

.download-data-btn:hover,
.download-map-btn:hover {
  background-color: #243047; /* Darker shade on hover */
  color: #d1d9e6; /* Lighter text color on hover */
}

.cancel-btn {
  background-color: white !important;
  color: #18202f !important;
  border: 1px solid #18202f !important;
  float: right !important;
}

.cancel-btn:hover {
  background-color: #b0bec5 !important; /* Darker shade on hover */
  color: #243047 !important;
}
.apply-btn {
  background-color: #18202f !important;
  color: white !important;
  border: 0px solid !important;
}

#form-input-control-map-portfolio {
  background: #f4f4f4;
  border-radius: 0px !important;
  box-shadow: none !important;
  height: 30px;
  border: 1px solid #d9d9d9 !important;
}
label[for="form-input-control-map-portfolio"] {
  font-size: 14px !important;
  margin-bottom: 0.1428em !important;
}
