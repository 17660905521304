.error-msg {
  color: red;
  margin: 5px 0;
  font-weight: bold;
}

.field {
  margin-bottom: 2em !important;
}

::placeholder {
  font-size: 13px;
  color: grey !important;
}

body {
  font-family: Poppins !important;
}
.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 35%;
  padding: 0px;
  z-index: 200;
  transition: 1.25s;
  background-color: #ecf0f3;
  overflow: hidden;
  box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
}

.switch__circle {
  position: absolute;
  width: 100%;
  height: 80%;
  border-radius: 50%;

  background-color: #ecf0f3;
  box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
  bottom: -50%;
  left: -50%;
  transition: 1.25s;
}
.switch__circle--t {
  top: -20%;
  left: 50%;
  width: 300px;
  height: 300px;
}
.switch__circle_login {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  background-color: #ecf0f3;
  box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
  bottom: -69%;
  left: -50%;
  transition: 1.25s;
}
input {
  box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9 !important;
}
@media only screen and (max-width: 600px) {
  .switch {
    width: 100%;
  }
  .switch {
    -moz-animation: cssAnimation 0s ease-in 2s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 2s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 2s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 2s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: linear;
  }
  @keyframes cssAnimation {
    from {
      width: 100%;
      height: 100%;
    }
    to {
      width: 0;
      height: 0;
    }
  }
  @-webkit-keyframes cssAnimation {
    from {
      width: 100%;
      height: 100%;
    }
    to {
      width: 0;
      height: 0;
      visibility: hidden;
    }
  }
}
