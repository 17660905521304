#login .icon {
  color: #005edc;
  margin: auto !important;
  padding-bottom: 2em;
  height: 100% !important;
}

#login .checkbox {
  padding: 0px !important;
}

#login .form-group {
  margin-bottom: 1000px !important;
}

.Analysis {
  border: 0px !important;
  border-bottom: 0.5px solid #015edc !important;
  border-radius: 0px !important;
}
.Analysis > .default.text {
  color: #015edc !important;
  font-size: 1.2rem !important;
  text-transform: uppercase !important;
}
#login a {
  color: #18202f !important;
}
