/* .ui.menu .item,{
	padding:1.2rem 1.4rem !important;
	border:0px;
	font-size:17px;
	letter-spacing: 0.5px;
	text-align:justify-all;
	float:center;
	transition: none

}
.ui.vertical.menu .item:before{
	height:0px !important;
}
.ui.menu .item a ,.accordion .title a {
	text-align: left;
	color:rgba(0,0,0,0.7);
	padding-left:10px;
	width:150px;
	font-family:'Montserrat';
	font-weight:400;

}

.ui.menu .item a:hover,.accordion .title a:hover{
	color:#1d99e8;
	transform:scale(1.2);


}
.ui.menu .item a i,.acccordion .title a i{
	margin-right:10px;
	text-align:justify;
}
.ui.left.visible.sidebar,
.ui.right.visible.sidebar{
	box-shadow:none !important;
	border:1px solid white;
}

.ui.visible.left.overlay.sidebar{
	margin-top:79.28px !important;

}
.ui.wide.left.sidebar{
	width:15%!important;
	border-right:1px solid rgba(34,36,38,0.15) ;
	background-color:#f7f6f6;
} */

.third-level-menu span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}

.mid {
  width: inherit;
  display: flex !important;
  position: fixed;
  top: 10px;
  right: 10px;
  justify-content: center !important;
}

/* Switch starts here */
.rocker {
  display: inline-block;
  position: relative;
  /*
	SIZE OF SWITCH
	==============
	All sizes are in em - therefore
	changing the font-size here
	will change the size of the switch.
	See .rocker-small below as example.
	*/
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #888;
  width: 7em;
  height: 4em;
  overflow: hidden;
  border-bottom: 0.5em solid #eee;
  margin: 0 !important;
}

.rocker-small {
  font-size: 0.75em; /* Sizes the switch */
  margin: 1em;
}

.rocker::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999;
  border: 0.5em solid #eee;
  border-bottom: 0;
}

.rocker input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-left,
.switch-right {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  width: 3em;
  transition: 0.2s;
}

.switch-left {
  height: 2.4em;
  width: 2.75em;
  left: 0.85em;
  bottom: 0.4em;
  background-color: #ddd;
  transform: rotate(15deg) skewX(15deg);
}

.switch-right {
  right: 0.5em;
  bottom: 0;
  background-color: #000000;
  color: #fff;
}

.switch-left::before,
.switch-right::before {
  content: "";
  position: absolute;
  width: 0.4em;
  height: 2.45em;
  bottom: -0.45em;
  background-color: #ccc;
  transform: skewY(-65deg);
}

.switch-left::before {
  left: -0.4em;
}

.switch-right::before {
  right: -0.375em;
  background-color: transparent;
  transform: skewY(65deg);
}

input:checked + .switch-left {
  background-color: #ffffff;
  color: #000000;
  bottom: 0px;
  left: 0.5em;
  height: 2.5em;
  width: 3em;
  transform: rotate(0deg) skewX(0deg);
}

input:checked + .switch-left::before {
  background-color: transparent;
  width: 3.0833em;
}

input:checked + .switch-left + .switch-right {
  background-color: #ddd;
  color: #888;
  bottom: 0.4em;
  right: 0.8em;
  height: 2.4em;
  width: 2.75em;
  transform: rotate(-15deg) skewX(-15deg);
}

input:checked + .switch-left + .switch-right::before {
  background-color: #ccc;
}

/* Keyboard Users */
input:focus + .switch-left {
  color: #333;
}

input:checked:focus + .switch-left {
  color: #fff;
}

input:focus + .switch-left + .switch-right {
  color: #fff;
}

input:checked:focus + .switch-left + .switch-right {
  color: #333;
}

.ui.selection.dropdown {
  background-color: #e6e7ee !important;
  border: 0px !important;
}
.ui.menu {
  box-shadow: none !important;
  border: 0px !important;
}
.shadow-soft {
  color: #888 !important;
  border-radius: 0.55rem !important;
  background: transparent !important;
  margin: 0 !important;
  padding: 5px !important;
  text-align: left !important;
}

/*.ant-menu-item-selected .iconsminds-home:before {
  content: "" !important;
  display: block !important;
  height: 2rem !important;
  width: 2.5rem !important;
  background: url(../assets/physical_risk.png) !important;
  background-size: cover !important;
}*/
.iconsminds-home:before {
  content: "" !important;
  display: block !important;
  height: 1.7rem !important;
  width: 1.7rem !important;
  background: url(../assets/physical_risk.png) !important;
  background-size: cover !important;
}
.iconsminds-file:before {
  content: "" !important;
  display: block !important;
  height: 1.7rem !important;
  width: 1.7rem !important;
  background: url(../assets/project.png) !important;
  background-size: cover !important;
}
/*.ant-menu-item-selected .iconsminds-dashboard:before {
  content: "" !important;
  display: block !important;
  height: 2rem !important;
  width: 2.5rem !important;
  background: url(../assets/dashboard.png) !important;
  background-size: cover !important;
}
.ant-menu-item-selected .iconsminds-building:before {
  display: block !important;
  height: 2rem !important;
  background: url(../assets/real_estate.png) !important;
  content: "" !important;
  background-size: cover !important;

  width: 2.5rem !important;
}*/
.bg-dark {
  background-color: #18202f !important;
  color: white !important;
}
.iconsminds-dashboard:before {
  content: "" !important;
  display: block !important;
  height: 1.7rem !important;
  width: 1.7rem !important;
  background: url(../assets/dashboard.png) !important;
  background-size: cover !important;
  color: #18202f !important;
}
.iconsminds-building:before {
  content: "" !important;
  display: block !important;
  height: 1.7rem !important;
  width: 1.7rem !important;
  background: url(../assets/real_estate.png) !important;
  background-size: cover !important;
  color: #18202f !important;
}
.iconsminds-pantone:before {
  content: "" !important;
  display: block !important;
  height: 1.7rem !important;
  width: 1.7rem !important;
  background: url(../assets/api_docs.png) !important;
  background-size: cover !important;
}
.iconsminds-methodology:before {
  content: "" !important;
  display: block !important;
  height: 1.7rem !important;
  width: 1.7rem !important;
  background: url(../assets/methodology.png) !important;
  background-size: cover !important;
  color: #18202f !important;
}
.iconsminds-logout:before {
  content: "" !important;
  display: block !important;
  height: 1.7rem !important;
  width: 1.7rem !important;
  background: url(../assets/logout.png) !important;
  background-size: cover !important;
  color: #18202f !important;
}

.ant-layout-sider {
  position: fixed !important;
  height: 110% !important;
  z-index: 300 !important;
  background-color: rgb(24, 32, 47) !important;
  /*background-image: linear-gradient(to right, #18202F 33%, #000044) !important;*/
}
.ant-layout-sider-trigger {
  background: rgb(24, 32, 47) !important;
}
.ant-menu.ant-menu-dark {
  /*background-image: linear-gradient(to right, #18202F 33%, #000044) !important;*/
  background-color: rgb(24, 32, 47) !important;
}

.ant-menu-item-selected {
  background: rgb(24, 32, 47) !important;
  box-shadow: 0px 4px 14px -1px rgba(0, 0, 0, 0.75);

  border-radius: 4px;
  align-self: center !important;
  width: 90% !important;
  margin-left: 2% !important;
  padding-left: 24px !important;
}
.ant-menu-item-selected li a {
  color: white !important;
}

.sidebar .main-menu ul li.active:after {
  display: none !important;
}
.sidebar .main-menu ul {
  align-items: flex-start !important;
}
.nav-item a {
  height: 10% !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 5% !important;
  border-bottom: none !important;
}
.ant-menu-item {
  height: 15% !important;
  line-height: 3rem !important;
}

.breadcrumb {
  background-color: #18202f !important;
  color: white !important;
}
.breadcrumb-item a {
  color: white !important;
}

/*.topnav .nav-link {
  font-size: 18px !important;
  color: white !important;
  padding-right: 1.5em !important;
  padding-left: 1.5em !important;
}*/
.dropdown-toggle::after {
  margin-left: 0.355em !important;
  margin-top: 0.355em !important;
}
.dropdown-menu {
  background-color: #18202f !important;
}
.dropdown-item {
  padding: 0.25em;
  color: white !important;
}
a.dropdown-item:hover {
  background-color: #18202f !important;
  color: white !important;
}
